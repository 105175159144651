import React from "react";




function Experience() {
    return (

        <div id="experienceBack">
                <div className="container" >

                <div className="row text-center justify-content-center mb-5">
        <div className="col-xl-6 col-lg-8">
            <h1 className="aboutmetxt" id="experience">My Experience</h1>
            <p className="text-muted">
            I'd consider myself a versatile professional with knowledge and experience in Web Development, UX & UI Design, Graphic Design, Multimedia Design & Motion Graphic Design.</p> 
                
                {/* I start my experice working as a Web developer in Konecta where I've been working with HTML and CSS changing content in a bank from Spain. After that I got a job with a recognise photographer called Jorge Amado, I help him to design his magazine showing his pictures (programs I used Adobe Illustrator, Photoshop, Indisgin). Not longer I finish the magazine I got a job opportunity as a Tutor in edition and Postproduction at the University where I gradueted, where I've been teaching how to use programs like Adobe Premiere, Adobe After Effects, Adobe Photoshop, Adobe Illustrator, Mocha, Particle illusion. I shared my last year of teaching working for a TV channel where I've been the responsable to create the new graphic identity of the TV News, for the long of 2 years I've been designing graphics, animations and working in the website and social medias.  */}
        </div>
    </div>
                      <div className="main-timeline">


                      <div className="timeline">
                            <div className="icon"></div>
                            <div className="date-content">
                                <div className="date-outer">
                                    <span className="date">
                                            <span className="month">Current</span>
                                    <span className="year" id="year2">2023</span>
                                    </span>
                                </div>
                            </div>
                            <div className="timeline-content">
                                <h5 className  ="title">Web Developer & Multimedia Designer</h5>
                                <p className="description">
                                <b>Feb. 2023 - Current | Event Merchandise Service</b> <br />
                                Redesign and program websites in React & Shopify <br />
Website maintenance, content creation, and sections creation.<br />
Photography of new products, photo editing, and product publication.<br /><br />

<b>Multimedia designer for events</b><br />
Design of the merchandise stores for the 4 football stadiums in New Zealand for the FIFA Women's World Cup.<br />
Create print designs for dressing up trailers and storefronts.<br /><br />

<b>Websites created</b><br />
- New Zealand football<br />
- New Zealand Cricket<br />
- New Zealand Netball <br />
- New Zealand Rugby League<br />
- SEKEM<br />
- Event Merchandise Service<br />
                                <br /><i><u>Programs used:</u> Adobe Photoshop, Adobe Illustrator
                                <br /><u>Programming:</u> HTML, CSS, JavaScript, TypeScrip React, Shopify</i></p>
                            </div>
                        </div>


                      <div className="timeline">
                            <div className="icon"></div>
                            <div className="date-content">
                                <div className="date-outer">
                                    <span className="date">
                                            <span className="month">Current</span>
                                    <span className="year" id="year2">2023</span>
                                    </span>
                                </div>
                            </div>
                            <div className="timeline-content">
                                <h5 className  ="title">Web Developer & Graphic Designer</h5>
                                <p className="description">
                                <b>Feb. 2023 - Current | KiwiKrane</b> <br />
                                Redesign and program website in React<br />
Website maintenance, content creation, and sections creation.<br />
Photography of new products, photo editing, and product publication.<br />
Create and design website, logos and/or graphic identity for companies working in association with Kiwikrane. <br />
                                <br /><i><u>Programs used:</u> Adobe Photoshop, Adobe Illustrator
                                <br /><u>Programming:</u> HTML, CSS, JavaScript, React, Shopify</i></p>
                            </div>
                        </div>


                        <div className="timeline">
                            <div className="icon"></div>
                            <div className="date-content">
                                <div className="date-outer">
                                    <span className="date">
                                            <span className="month">1.5 Years</span>
                                    <span className="year" id="year2">2021-2023</span>
                                    </span>
                                </div>
                            </div>
                            <div className="timeline-content">
                                <h5 className  ="title">Multimedia Designer</h5>
                                <p className="description">
                                <b>Sept. 2021 - Feb. 2023 | Good Feeling Ltd</b> <br />
                                Design of company brand <br />
                                Graphics and photography for social media (Instagram and Facebook) <br />
                                UX and web design <br />
                                Motion graphics 
                                <br /><i><u>Programs used:</u> Adobe Photoshop, Adobe Illustrator, Adobe Premiere, Adobe After Effects.
                                <br /><u>Programming:</u> HTML, CSS, JavaScript</i></p>
                            </div>
                        </div>
                  
                        <div className="timeline">
                            <div className="icon"></div>
                            <div className="date-content">
                                <div className="date-outer">
                                    <span className="date">
                                            <span className="month">Freelance</span>
                                    <span className="year">2022</span>
                                    </span>
                                </div>
                            </div>
                            <div className="timeline-content">
                                <h5 className="title">UX, UI Designer & Web Developer</h5>
                                <p className="description">
                                <b>Oct. - Dec. 2022 | Sophie Dyson</b> <br />
                                    Created personal brand, logo & website 
                                <br /><i><u>Programs used:</u> Adobe Photoshop, Adobe Illustrator
                                <br /><u>Programming:</u> HTML, CSS, JavaScript, React, Bootstrap, Emailjs, Node.js </i></p>
                            </div>
                        </div>
                 
                        <div className="timeline">
                            <div className="icon"></div>
                            <div className="date-content">
                                <div className="date-outer">
                                    <span className="date">
                                            <span className="month">2 Years</span>
                                    <span className="year" id="year2">2020-2021</span>
                                    </span>
                                </div>
                            </div>
                            <div className="timeline-content">
                                <h5 className="title">Graphic Designer</h5>
                                <p className="description">
                                <b>June 2020 - Aug. 2021 | South America Oven</b> <br />
                                Graphic design such as menus, advertisements and shop signage<br />
                                Video creation and editing for promotions
                                <br /><i><u>Programs used:</u> Adobe Photoshop, Adobe Illustrator, Adobe Premiere, Adobe After Effects
                                <br /><u>Programming:</u> HTML, CSS </i></p>
                            </div>
                        </div>
            
                        <div className="timeline">
                            <div className="icon"></div>
                            <div className="date-content">
                                <div className="date-outer">
                                    <span className="date">
                                            <span className="month">2 Years</span>
                                    <span className="year" id='year2'>2018-2020</span>
                                    </span>
                                </div>
                            </div>
                            <div className="timeline-content">
                                <h5 className="title">Graphic Designer & Motion Graphics</h5>
                                <p className="description">
                                <b>Jan. 2018 - March 2020 | Insomniak Records</b> <br />
                                Visual animation during live events <br />
                                Graphics for advertisements
                                <br /><i><u>Programs used:</u> Adobe Photoshop, Adobe Illustrator, Adobe Premiere, Adobe After Effects, Resolume</i></p>
                            </div>
                        </div>

                        {/* <div className="timeline">
                            <div className="icon"></div>
                            <div className="date-content">
                                <div className="date-outer">
                                    <span className="date">
                                            <span className="month">Freelance</span>
                                    <span className="year">2019</span>
                                    </span>
                                </div>
                            </div>
                            <div className="timeline-content">
                                <h5 className="title">Motion Graphic Designer</h5>
                                <p className="description">
                                <b>Dic. 2019 - Ene. 2020 | Sky TV Network.</b> <br />
                                Licence covered. Video promotions for the sports channel, social media, graphic support and promotions for childrens programs
                                <br /><i><u>Program used:</u> Adobe Photoshop, Adobe Illustrator, Adobe Premiere, Adobe After Effects</i></p>
                            </div>
                        </div> */}

                        <div className="timeline">
                            <div className="icon"></div>
                            <div className="date-content">
                                <div className="date-outer">
                                    <span className="date">
                                            <span className="month">Freelancer</span>
                                    <span className="year">2015</span>
                                    </span>
                                </div>
                            </div>
                            <div className="timeline-content">
                                <h5 className="title">Multimedia Designer</h5>
                                <p className="description">
                                <b>May - Dec. 2015 | Osiris Events</b> <br />
                                Design of company brand <br /> Promotional material for advertisements <br /> Animation <br /> Photography for live events
                                <br /><i><u>Programs used:</u> Adobe Photoshop, Adobe Illustrator, Adobe Premiere, Adobe After Effects, Cinema 4D</i>
                                </p>
                            </div>
                        </div>

                        <div className="timeline">
                            <div className="icon"></div>
                            <div className="date-content">
                                <div className="date-outer">
                                    <span className="date">
                                            <span className="month">2 years</span>
                                    <span className="year" id="year2">2013-2015</span>
                                    </span>
                                </div>
                            </div>
                            <div className="timeline-content">
                                <h5 className="title">Multimedia Designer</h5>
                                <p className="description">
                                <b>Oct. 2013 - Oct. 2015 | Televisa/Channel 9</b> <br />
                                Multimedia design including video creator, motion graphic, postproduction and fx <br /> Design of company brand <br /> New opening animation for the television station <br /> Graphic design such as logos and stills
                                <br /><i><u>Programs used:</u> Adobe Photoshop, Adobe Illustrator, Adobe Premiere, Adobe After Effects, Cinema 4D
                                <br /><u>Programming:</u> HTML, CSS </i></p>
                            </div>
                        </div>

                        {/* <div className="timeline">
                            <div className="icon"></div>
                            <div className="date-content">
                                <div className="date-outer">
                                    <span className="date">
                                            <span className="month">3 years</span>
                                    <span className="year">2012</span>
                                    </span>
                                </div>
                            </div>
                            <div className="timeline-content">
                                <h5 className="title">Audiovisual Tutor</h5>
                                <p className="description">
                                <b>Feb. 2012 - Dic. 2014 | Da Vinci Multimedia Art School</b> <br />
                                Audiovisual edition, post-production and motion graphics (Adobe After Effects, Adobe Premiere, Mocha and Particle Illusion)
                                <br /><i><u>Program used:</u> Adobe Photoshop, Adobe Illustrator, Adobe Indesgin</i></p>
                            </div>
                        </div> */}

                        {/* <div className="timeline">
                            <div className="icon"></div>
                            <div className="date-content">
                                <div className="date-outer">
                                    <span className="date">
                                            <span className="month">2 years</span>
                                    <span className="year">2011</span>
                                    </span>
                                </div>
                            </div>
                            <div className="timeline-content">
                                <h5 className="title">Graphic Designer</h5>
                                <p className="description">
                                <b>Feb. 2011 - Dic. 2012 | J.A.G</b> <br />
                                Photo retouching,
                                Layout and design magazines like Intercountries, Vea más.
                                Design of advertisements.
                                <br /><i><u>Program used:</u> Adobe Photoshop, Adobe Illustrator, Adobe Indisgin</i></p>
                            </div>
                        </div> */}

                        {/* <div className="timeline">
                            <div className="icon"></div>
                            <div className="date-content">
                                <div className="date-outer">
                                    <span className="date">
                                            <span className="month">2 years</span>
                                    <span className="year">2011</span>
                                    </span>
                                </div>
                            </div>
                            <div className="timeline-content">
                                <h5 className="title">Web Maintenance</h5>
                                <p className="description">
                                <b>Ene. 2010 - Dic. 2011 | Grupo Konecta</b> <br />
                                Maintenance Web at Santander Río Bank, and updates pressroom.
                                <br /> <i><u>programming:</u> HTML, CSS </i></p>
                            </div>
                        </div> */}


                    </div>
                </div>
            </div>   
    
                
    )
};

export default Experience;




