import React from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Carousel from "./carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Carousel1 from "./carouselInvestigar";
import CarouselTongariro from "./carouselTongariro";
import CarouselGF from "./carouselGF";
import CarouselSAO from "./carouselSAO";
import CarouselOsiris from "./carouselOsiris";
import CarouselTL9 from "./carouselTL9";
// import ReactPlayer from 'react-player'
// import { MDBContainer } from "mdb-react-ui-kit";

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          UX - UI Designer
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Whitecliffe College</h4>
        <CarouselTongariro />
        <h5>Website Project</h5>

          <p>Country: New Zealand<br />
          
          This is a website that I created for a Whitecliffe College project
          where I used HTML, CSS, JavaScript and React. I used Adobe Illustrator for UX design and logo design
          as well as Photoshop for the images. 
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

function MyVerticallyCenteredModal2 (props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          UX/UI Designer and Web Developer 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Sophie Dyson</h4>
          <Carousel />
        <h5>Website and Branding</h5>
 
        <p>Country: New Zealand<br />
      
          Sophie Dyson was my client who needed me to design a personal brand and website. I used HTML, CSS, JavaScript, React, Bootstrap and EmailJS for her website, and Adobe Illustrator and Photoshop for UX and UI design. 
        </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }


  function MyVerticallyCenteredModal3 (props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          UX/UI and Graphic Designer
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Good Feeling Limited</h4>
          <CarouselGF /> 
          <h5>Website, Branding and Social Media</h5>
        
          <p>Country: New Zealand<br />
         
          In Good Feeling Limited, I designed the brand including, but not limited to, the logo, website, signage and business cards as well as social media marketing and more. <br />
          <a href="https://goodfeeling.co.nz/" target="_blank">Link to Website </a> <br />
          <a href="https://www.instagram.com/goodfeeling.dumplings/" target="_blank">Link to Instagram </a> <br />
          <a href=" https://www.facebook.com/goodfeelingdumplings" target="_blank">Link to Facebook </a> 
        </p>

        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }


  function MyVerticallyCenteredModal4 (props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          Graphic Designer
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>South American Oven</h4>
          <CarouselSAO />
          <h5>Graphic Design and Animation</h5>
    
          <p>Country: New Zealand<br />
          
        I designed graphics and promotional material for South American Oven
        as well as videos. I used Adobe Illustrator, Photoshop, Premiere and After Effects.

        </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }



  function MyVerticallyCenteredModal5 (props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {/* Print Design */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <h4>Osiris Events</h4> */}
          <CarouselOsiris />
          <h5>Graphic Design and Animation</h5>
        <p>I designed the brand from the logo, business cards, letterhead, folders and animations for a 10 meter widescreen.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }



  function MyVerticallyCenteredModal6 (props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Motion Graphic Designer
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Channel 9 / Insomniak / Freelance</h4>
          <CarouselTL9 /> 
          <h5>Graphic and Motion Graphic Design</h5>
        <p>I redesigned the logo and opening animation for the TV News, 2D and 3D animations, graphics, motion graphics, scene recreations and other material for TV News.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }







function Portfolio() {
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShow2, setModalShow2] = React.useState(false);
  const [modalShow3, setModalShow3] = React.useState(false);
  const [modalShow4, setModalShow4] = React.useState(false);
  const [modalShow5, setModalShow5] = React.useState(false);
  const [modalShow6, setModalShow6] = React.useState(false);

  return (
   

<div className="portfolio" id="portfolio">
<div className="row text-center justify-content-center mb-5">
        <div className="col-xl-6 col-lg-8">
            {/* <h2 className="font-weight-bold">My Education</h2> */}
            <h1 className="portfoliotxt">My Portfolio</h1>
            <p className="text-muted">Here you can find some of my work as a UX & UI Designer, Graphic Designer and Motion Graphic Designer.  </p>
        </div>
    </div>
            <div id="gallery">
                <div class="row">
                    <div class="col-lg-4 col-md-12 mb-4 mb-lg-0" >
                        <img
                        src="img/portfolio/portfolio5.png"
                        variant="success"
                        onClick={() => setModalShow(true)}
                        class="w-100 shadow-1-strong rounded mb-4"
                        alt="Boat on Calm Water"
                        id="opacityHover"
                        /> 

                        <img
                        src="img/portfolio/portfolio2-04.png"
                        onClick={() => setModalShow2(true)}
                        class="w-100 shadow-1-strong rounded mb-4"
                        alt="Wintry Mountain Landscape"
                        id="opacityHover"
                        />




                        <MyVerticallyCenteredModal
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                        />

                        <MyVerticallyCenteredModal2
                            show={modalShow2}
                            onHide={() => setModalShow2(false)}
                        />    

                     </div>



                    <div class="col-lg-4 mb-4 mb-lg-0">
                        <img
                            src="img/portfolio/portfolio2.png"
                            onClick={() => setModalShow3(true)}
                            class="w-100 shadow-1-strong rounded mb-4"
                            alt="Mountains in the Clouds"
                            id="opacityHover"
                        />

                        <img
                            src="img/portfolio/portfolio4.png"
                            onClick={() => setModalShow4(true)}
                            class="w-100 shadow-1-strong rounded mb-4"
                            alt="Boat on Calm Water"
                            id="opacityHover"
                        />

                        <MyVerticallyCenteredModal3
                            show={modalShow3}
                            onHide={() => setModalShow3(false)}
                        />

                        <MyVerticallyCenteredModal4
                            show={modalShow4}
                            onHide={() => setModalShow4(false)}
                        />  

                    </div>


                    <div class="col-lg-4 mb-4 mb-lg-0">
                        <img
                        src="img/portfolio/portfolioPV-09.png"
                        onClick={() => setModalShow5(true)}
                        class="w-100 shadow-1-strong rounded mb-4"
                        alt="Waves at Sea"
                        id="opacityHover"
                        />

                        <img
                        src="img/portfolio/portfolioTL9-.png"
                        onClick={() => setModalShow6(true)}
                        class="w-100 shadow-1-strong rounded mb-4"
                        alt="Yosemite National Park"
                        id="opacityHover"
                        />

                        <MyVerticallyCenteredModal5
                            show={modalShow5}
                            onHide={() => setModalShow5(false)}
                        />

                        <MyVerticallyCenteredModal6
                            show={modalShow6}
                            onHide={() => setModalShow6(false)}
                        />  

                    </div>

      </div>
      </div>
      </div>

    

    
  );
}

export default Portfolio;