import { ReactDOM } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

function CarouselTongariro() {
  return (
            <Carousel>
                <div>
                <img
                    src="img/tongariro/tongariro.png"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt="Yosemite National Park"
                    id="imgCarousel"
                    />
                    <p className="legend">Home Page</p>
                </div>
                <div>
                <img
                    src="img/tongariro/tongariro2.png"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt="Yosemite National Park"
                    id="imgCarousel"
                    />
                    <p className="legend">Movie Information</p>
                </div>
                <div>
                <img
                    src="img/tongariro/tongariro3.png"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt="Yosemite National Park"
                    id="imgCarousel"
                    />
                    <p className="legend">Check Out</p>
                </div>
                
            </Carousel>

)
}


export default CarouselTongariro;



