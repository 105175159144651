import { ReactDOM } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

function CarouselGF() {
  return (
            <Carousel>
                                <div>
                    <img
                    src="img/goodFeeling/gfGraphic-17.png"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt="Yosemite National Park"
                    id="imgCarousel"
                    />
                    <p className="legend">Promotional Material</p>
                </div>
                <div>
                <img
                    src="img/goodFeeling/gf01.png"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt="Yosemite National Park"
                    id="imgCarousel"
                    />
                    <p className="legend">Checkout Page on Website</p>
                </div>
                <div>
                <img
                    src="img/goodFeeling/gf02.png"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt="Yosemite National Park"
                    id="imgCarousel"
                    />
                    <p className="legend">Website on Computer</p>
                </div>
                <div>
                <img
                    src="img/goodFeeling/gf03.png"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt="Yosemite National Park"
                    id="imgCarousel"
                    />
                    <p className="legend">Product Page on Website</p>
                </div>
                <div>
                <img
                    src="img/goodFeeling/gf04.png"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt="Yosemite National Park"
                    id="imgCarousel"
                    />
                    <p className="legend">How To Cook Section on Website</p>
                </div>

                <div>
                    <img
                    src="img/goodFeeling/gfGraphic-13-13.png"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt="Yosemite National Park"
                    id="imgCarousel"
                    />
                    <p className="legend">Signage</p>
                </div>
                <div>
                    <img
                    src="img/goodFeeling/gfGraphic-14-14.png"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt="Yosemite National Park"
                    id="imgCarousel"
                    />
                    <p className="legend">Business Cards and Fridge Magnets</p>
                </div>
                <div>
                    <img
                    src="img/goodFeeling/gfGraphic-14-15.png"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt="Yosemite National Park"
                    id="imgCarousel"
                    />
                    <p className="legend">Packaging</p>
                </div>
                <div>
                    <img
                    src="img/goodFeeling/gfGraphic-14-16.png"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt="Yosemite National Park"
                    id="imgCarousel"
                    />
                    <p className="legend">Instagram</p>
                </div>
                <div className="ratio ratio-16x9">
                    <iframe
                    src="https://www.youtube.com/embed/-2io3X4oHz4"
                    title="Instagram Reel"
                    allowfullscreen
                    ></iframe>
                </div>
            </Carousel>

)
}


export default CarouselGF;



