import './App.css';
import Header from './header';
import {BrowserRouter as Router, Routes, Route, Link, Switch, NavLink } from "react-router-dom"; 
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './home';
import Footer from './footer';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import Portfolio from './portfolio';
import 'bootstrap/dist/css/bootstrap.min.css';
import DentonsPortfolio from './DentonsPortfolio';
import BoxcribPortfolio from './BoxcribPortfolio';
import NZMEPortfolio from './NZMEPortfolio';
import Printvideos from "./printVideos";
import PortfolioAucklandUni from './portfolioAucklandUni';
import PortfolioSpruik from './portfolioSpruik';


function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/prueba2" element={<Prueba2 />} />
        <Route path="/prueba" element={<Prueba />} />
        <Route path="/contact" element={<Contact />} /> */}
        <Route path="/portfolioAucklandUni" element={<PortfolioAucklandUni />} />
        <Route path="/NZMEPortfolio" element={<NZMEPortfolio />} />
        <Route path="/BoxcribPortfolio" element={<BoxcribPortfolio />} />
        <Route path="/DentonsPortfolio" element={<DentonsPortfolio />} />
        <Route path="/portfolioSpruik" element={<PortfolioSpruik />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/printVideos" element={<Printvideos />} />

      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
