import React from "react";
import Contact from "./contactoBorrar";
import Skills from "./skills";
import Education from "./education";
import Experience from "./experience";
import Portfolio from "./portfolio";
import Skills2 from "./skills2";
import DentonsPortfolio from "./DentonsPortfolio";


function HomePart1() {
    return (
        <div className="homePart1">
            <div className="containerHome" >
                        
                <div className="text">
                    <img className="fotoNati" src="img/fotocirculo-01.png" alt="Natali Larroza" /> 
        
                    <div className="homeText">
                        <div className>
                            <h3 className="tituloN">Natali Larroza</h3>
                        </div>
                    <div className="infocourse1" >
                        <p>Multimedia Designer</p>
                        <p id="cv">Click <a href="./img/LarrozaNataliCV.pdf" id="style-2" data-replace="here" target="_blank"><span>here</span></a> to Download CV</p>
                    </div>
                </div>
         
                </div>
    </div>

        <div className="aboutMe" >
            <div className="aboutMeTxt ">
                <h1 className="aboutmetxt_"><b>ABOUT ME</b></h1>
                <h2 className="hire">Experienced Web Developer with a Solid Foundation in Multimedia Design</h2>
                <p className="aboutmetxt">
                    My name is <b>Natali Larroza</b>. Passionate web developer with a strong background in multimedia design and a knack for web development. I excel at blending creativity and technical precision to create visually captivating and user-friendly websites.
                    {/* <br />Looking for new professional challenges to expand on skills. Excels at adapting to new roles quickly and can apply skills to any situation.<br /> */}
                    {/* <br />• One decade of design experience from Multimedia to Video Editor and Motion Graphics */}
                    <br />• Creative and effective problem solving abilities
                    <br />• Able to multitask and complete goals in a timely and accurate manner
                    <br />• Proficient in Adobe suite, Microsoft suite, HTML, CSS, Javascript, Bootstrap, React and Shopify.
                    </p> 
{/*                     
                    I am a New Zealand-based <b>Multimedia & Motion Graphic Designer with knowledge in UX Designer & Web Developer</b>. With a decade of experience in this field.
                    <br />I can offer your company a <b>complete vision</b> based around your needs from the initial design through to the <b>finished product</b>.</p>       */}
            </div>
        </div>


        <Experience />
        <div className="skills">
            <Skills2 />
        </div>
        <Education />
        
        <Portfolio />
        {/* <a href="/BoxcribPortfolio" id="colorblanco">I</a>
        <a href="/NZMEPortfolio" id="colorblanco">I</a> */}
</div>
    )
};

export default HomePart1;




