import { ReactDOM } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

function Carousel1() {
  return (
            <Carousel>
                <div>
                <img
                    src="img/Sophie/mockupSophie-02.png"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt="Yosemite National Park"
                    id="imgCarousel"
                    />
                    <p className="legend">Computer view of the website</p>
                </div>
                <div>
                <img
                    src="img/Sophie/mockupSophie-01.png"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt="Yosemite National Park"
                    id="imgCarousel"
                    />
                    <p className="legend">Phone view of the website</p>
                </div>
                <div>
                <img
                    src="img/Sophie/mockupSophie-03.png"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt="Yosemite National Park"
                    id="imgCarousel"
                    />
                    <p className="legend">Contact Form</p>
                </div>
                
            </Carousel>

)
}


export default Carousel1;



