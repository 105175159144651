import { ReactDOM } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ReactPlayer from 'react-player'

import { MDBContainer } from "mdb-react-ui-kit";


function CarouselSAO() {
  return (
            <Carousel>
                <div>
                <img
                    src="img/SAO/sao-17.png"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt="Yosemite National Park"
                    id="imgCarousel"
                    />
                    <p className="legend">Shop Signage</p>
                </div>
                <div>
                <img
                    src="img/SAO/sao-18.png"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt="Yosemite National Park"
                    id="imgCarousel"
                    />
                    <p className="legend">Shop Signage</p>
                </div>
                <div>
                <img
                    src="img/SAO/sao-19.png"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt="Yosemite National Park"
                    id="imgCarousel"
                    />
                    <p className="legend">Shop Signage</p>
                </div>
                <div>
                <img
                    src="img/SAO/sao-20.png"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt=""
                    id="imgCarousel"
                    />
                    <p className="legend">Information and stamp for takeaway box with QR Code</p>
                </div>
                <div>
                {/* <MDBContainer> */}
                <div className="ratio ratio-16x9">
                    <iframe
                    src="https://www.youtube.com/embed/l8KWL0gzegs"
                    title="Sao"
                    allowfullscreen
                    id="imgCarousel"
                    ></iframe>
                    </div>
                    <p className="legend">Promotional Video for Fuller's Ferry</p>
                
                {/* </MDBContainer> */}
                </div>
                
            </Carousel>

)
}


export default CarouselSAO;



