import { ReactDOM } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

function CarouselTL9() {
  return (
            <Carousel>
                <div className="ratio ratio-16x9">
                    <iframe
                    src="https://www.youtube.com/embed/bA0vco8_HpE"
                    title="Reel TL9"
                    allowfullscreen
                    ></iframe>
                </div>
                <div className="ratio ratio-16x9">
                    <iframe
                    src="https://www.youtube.com/embed/SWXCtZNbYEY"
                    title="Motion Graphics"
                    allowfullscreen
                    ></iframe>
                </div>
                <div className="ratio ratio-16x9">
                    <iframe
                    src="https://www.youtube.com/embed/sW6PHeGi5WQ"
                    title="WorldCup"
                    allowfullscreen
                    ></iframe>
                </div>
                <div className="ratio ratio-16x9">
                    <iframe
                    src="https://www.youtube.com/embed/PtPc3jb6YGY" 
                    title="Insomniak"
                    allowfullscreen
                    ></iframe>
                </div>
                <div className="ratio ratio-16x9">
                    <iframe
                    src="https://www.youtube.com/embed/dJxrnLh2EsU" 
                    title="Game"
                    allowfullscreen
                    ></iframe>
                </div>
                <div className="ratio ratio-16x9">
                    <iframe
                    src="https://www.youtube.com/embed/z241_o1GOIk"
                    title="felicesFiestas"
                    allowfullscreen
                    ></iframe>
                </div>
            </Carousel>

)
}


export default CarouselTL9;



