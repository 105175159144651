import React from "react";




function Education() {
    return (

        <div className="containerEstudios" id="education">                      
        <div className="row text-center justify-content-center mb-5">
            <div className="col-xl-6 col-lg-8" id="educationBox">
                {/* <h2 className="font-weight-bold">My Education</h2> */}
                <h1 className="aboutmetxt">My Education</h1>
                <p className="text-muted">I started my career as a designer with a diploma in Graphic Design and then expanded my knowledge with a degree in Multimedia Design. I took an introductory course in coding and I have now recently finished my studies in Web Development at Whitecliffe College with an average of A+. </p>
            </div>
        </div>
    
        <div className="row">
            <div className="col">
                <div className="timeline-steps aos-init aos-animate" data-aos="fade-up">
                    <div className="timeline-step">
                        <div className="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2003">
                            <div className="inner-circle"></div>
                            <p className="h6 mt-3 mb-1">2006-2008</p>
                            <p className="h6 text-muted mb-0 mb-lg-0">Graphic Design</p>
                        </div>
             
                    </div>
                    <div className="timeline-step">
                        <div className="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2004">
                            <div className="inner-circle"></div>
                            <p className="h6 mt-3 mb-1">2008-2011</p>
                            <p className="h6 text-muted mb-0 mb-lg-0">Multimedia Design</p>
                        </div>
                    </div>
                     <div className="timeline-step">
                        <div className="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2005">
                            <div className="inner-circle"></div>
                            <p className="h6 mt-3 mb-1">2012</p>
                            <p className="h6 text-muted mb-0 mb-lg-0">Coding Course</p>
                        </div>
                    </div>
                    {/*<div className="timeline-step">
                        <div className="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2005">
                            <div className="inner-circle"></div>
                            <p className="h6 mt-3 mb-1">2018</p>
                            <p className="h6 text-muted mb-0 mb-lg-0">Principal principles</p>
                        </div>
                    </div> */}
                    <div className="timeline-step">
                        <div className="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2010">
                            <div className="inner-circle"></div>
                            <p className="h6 mt-3 mb-1">2022</p>
                            <p className="h6 text-muted mb-0 mb-lg-0">Web Development</p>
                        </div>
                    </div>
                    {/* <div className="timeline-step mb-0">
                        <div className="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2020">
                            <div className="inner-circle"></div>
                            <p className="h6 mt-3 mb-1">2020</p>
                            <p className="h6 text-muted mb-0 mb-lg-0">In Fortune 500</p>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    </div>
    
                
    )
};

export default Education;




