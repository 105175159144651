import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";



function PortfolioAucklandUni() {
    return (
<div className="portfolioDentons">
                     <h1 id="dentons">Portfolio</h1>
                     <h2 className="centerh2">The University of Aucklandy</h2>
                    



            <h4 id="dentons">Motion Design Artist</h4>

            <div className="topspace">
                <h5 id="center">Reel Natali Larroza - Muledimedia Designer<br /> <br /></h5>
                <h6 id="center">Adobe Illustrator, Adobe After Effects, Adobe Premiere, Adobe Photoshop
                                <br /></h6>
                
                <div className="ratio ratio-16x9" id="carouselPrintDentons">
                    <iframe
                    src="https://www.youtube.com/embed/SWXCtZNbYEY"
                    title="Reel TL9"
                    allowfullscreen
                    >    
                    </iframe>
                </div>
            </div>


            <div className="topspace">
                <h5 id="center">TV News - Intro Animated<br /> <br /></h5>
                <h6 id="center">Adobe Photoshop, Adobe Illustrator, Adobe After Effects, Cinema 4D
                                <br /></h6>
                
                <div className="ratio ratio-16x9" id="carouselPrintDentons">
                    <iframe
                    src="https://www.youtube.com/embed/qtPD5odyD6U"
                    title="Reel TL9"
                    allowfullscreen
                    >    
                    </iframe>
                </div>
            </div>


            <div className="topspace">
                <h5 id="center">Animated Sign  <br /> <br /></h5>
                <h6 id="center">Adobe Photoshop, Adobe Illustrator, Adobe After Effects
                                <br /></h6>
                
                <div className="ratio ratio-16x9" id="carouselPrintDentons">
                    <iframe
                    src="https://www.youtube.com/embed/LZxP-KSVqWI"
                    title="Reel TL9"
                    allowfullscreen
                    >    
                    </iframe>
                </div>
            </div>




        
            <div className="topspace">
                <h5 id="center">Animated Infographic  <br /> <br /></h5>
                <h6 id="center">Adobe Photoshop, Adobe Illustrator, Adobe Premiere
                                <br /></h6>
                
                <div className="ratio ratio-16x9" id="carouselPrintDentons">
                    <iframe
                    src="https://www.youtube.com/embed/ZCBfjAh20Zk"
                    title="Reel TL9"
                    allowfullscreen
                    >    
                    </iframe>
                </div>
            </div>



            <div className="topspace">
                <h5 id="center">Reel - Good Feeling<br /> <br /></h5>
                <h6 id="center">Adobe Photoshop, Adobe Illustrator, Adobe After Effects
                                <br /></h6>
                
                <div className="ratio ratio-16x9" id="carouselPrintDentons">
                    <iframe
                    src="https://www.youtube.com/embed/-2io3X4oHz4"
                    title="Reel TL9"
                    allowfullscreen
                    >    
                    </iframe>
                </div>
            </div>


            <div className="topspace">
                <h5 id="center">Logo animation - Insomniak Records<br /> <br /></h5>
                <h6 id="center">Adobe Illustrator, Adobe After Effects
                                <br /></h6>
                
                <div className="ratio ratio-16x9" id="carouselPrintDentons">
                    <iframe
                    src="https://www.youtube.com/embed/PtPc3jb6YGY"
                    title="Reel TL9"
                    allowfullscreen
                    >    
                    </iframe>
                </div>
            </div>
            

            <div className="topspace">
                <h5 id="center">Promotion animation - South American Oven<br /> <br /></h5>
                <h6 id="center">Adobe Illustrator, Adobe Photoshop, Adobe After Effects
                                <br /></h6>
                
                <div className="ratio ratio-16x9" id="carouselPrintDentons">
                    <iframe
                    src="https://www.youtube.com/embed/l8KWL0gzegs"
                    title="Reel TL9"
                    allowfullscreen
                    >    
                    </iframe>
                </div>
            </div>

            <div className="topspace">
                <h5 id="center">TV News Animation<br /> <br /></h5>
                <h6 id="center">Adobe Illustrator, Adobe Photoshop, Adobe After Effects
                                <br /></h6>
                
                <div className="ratio ratio-16x9" id="carouselPrintDentons">
                    <iframe
                    src="https://www.youtube.com/embed/Hx0O38KNB0g"
                    title="Reel TL9"
                    allowfullscreen
                    >    
                    </iframe>
                </div>
            </div>

            <div className="topspace">
                <h5 id="center">World Cup 2014<br /> <br /></h5>
                <h6 id="center">Adobe Illustrator, Adobe Photoshop, Adobe After Effects
                                <br /></h6>
                
                <div className="ratio ratio-16x9" id="carouselPrintDentons">
                    <iframe
                    src="https://www.youtube.com/embed/sW6PHeGi5WQ"
                    title="Reel TL9"
                    allowfullscreen
                    >    
                    </iframe>
                </div>
            </div>

            <div className="topspace">
            <h5 id="center">This is a reel where I showed most of the motion graphics and video editor that I made in TV Channel. <br />
                I showed the way that I designed and how is look like on TV <br /> <br />
                One of my main jobs was to design animated templates so that any other operator can change the required text.</h5>
                <br /><br /><h6 id="center">Multimedia design including animation, postproduction and fx <br /> Design of company brand <br /> New opening animation for the television station <br /> Graphic design such as logos and stills
                                <br /><br />
                                Adobe Photoshop, Adobe Illustrator, Adobe Premiere, Adobe After Effects, Cinema 4D</h6>
                
                <div className="ratio ratio-16x9" id="carouselPrintDentons">
                    <iframe
                    src="https://www.youtube.com/embed/bA0vco8_HpE"
                    title="Reel TL9"
                    allowfullscreen
                    >    
                    </iframe>
                </div>
            </div>

 




           
</div>

    )
}

export default PortfolioAucklandUni; 