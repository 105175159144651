import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';




function Skills2() {
    return (
      <div className='skills2'>
        <h1 className="skills2_">Skills</h1>
            <ul className="list-group list-group-horizontal">
            <li className="list-group-item">Adobe After Effects</li>
                <li className="list-group-item">Adobe Premiere</li>
                <li className="list-group-item">Adobe Photoshop</li>
            </ul>
            <ul className="list-group list-group-horizontal">
            <li className="list-group-item">Adobe Illustrator</li>
            <li className="list-group-item">Adobe Indesign</li>
                <li className="list-group-item">Adobe XD</li>
                <li className="list-group-item">Figma</li>
            </ul>
            <ul className="list-group list-group-horizontal">
            <li className="list-group-item">HTML</li>
                <li className="list-group-item">CSS</li>
                <li className="list-group-item">JavaScipt</li>
            </ul>
            <ul className="list-group list-group-horizontal">
                <li className="list-group-item">React</li>
                <li className="list-group-item">Bootstrap</li>
                <li className="list-group-item">Phyton</li>
                <li className="list-group-item">MySQL</li>
            </ul>
      </div>
    )
};

export default Skills2;




