// import React from "react";

import {
    MDBFooter,
    MDBContainer,
    MDBCol,
    MDBRow,
  } from 'mdb-react-ui-kit';
  
  
  
  
  import { yupToFormErrors } from 'formik';
  import React, { useState, useRef } from 'react';
  // import React, { useState, useRef } from 'react';
  import Button from 'react-bootstrap/Button';
  import Col from 'react-bootstrap/Col';
  import Form from 'react-bootstrap/Form';
  import InputGroup from 'react-bootstrap/InputGroup';
  import Row from 'react-bootstrap/Row';
  import { useNavigate } from 'react-router-dom';
  import emailjs from '@emailjs/browser';
  
  
  
  
  
  export const Footer = () => {
    const form = useRef();
    const [validated, setValidated] = useState(false);
  
    const sendEmail = (e) => {
      e.preventDefault();
      console.log("sendEmail()");
  
      
      emailjs.sendForm('service_e4xwbq3', 'template_ll9netc', form.current, 'Jzpn3poRqPiiSsXGo')
      
      
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
        e.target.reset()
    };
  
      const handleSubmit = (event) => {
        event.preventDefault();
        const form_target = event.currentTarget;
  
        if (form_target.checkValidity() === false) {
          setValidated(true);
          event.stopPropagation();
          //
          
        }
        else {
          emailjs.sendForm('service_e4xwbq3', 'template_ll9netc', form.current, 'Jzpn3poRqPiiSsXGo')
            .then((result) => {
                console.log("Email is sent:", result.text);
            }, (error) => {
                console.log(error.text);
            });
  
          window.open('/', "_self");
        }
    
      };
  
  
  
  
  
    return ( 
    <div className="footer" id="contacto">
  
  
      <MDBFooter className='text-body text-center text-lg-left'>
        <MDBContainer className='p-4'>
          <MDBRow>
            <MDBCol lg='6' md='12' className='mb-4 mb-md-0 ' id="infoFooter" >
              <div className>
                <h3 className="tituloFooter"id="infoFooterTitulo">Natali Larroza</h3>
              </div>
              <div className="infocourseFooter" id="infoFooterTitulo2">
                <p>Web Developer<br />
                  UX, UI & Multimedia Designer</p>
              </div>

            </MDBCol>
  
  
  
            <MDBCol lg='6' md='12' className='mb-4 mb-md-0'>
            <div className='margentop'>
              <ul className='myInformation list-unstyled mb-0' id="infoFooterTitulo3">
                <li>
                  <p className='margentop'><b>Email: </b>natali.larroza@gmail.com </p>
                  <p><b>Phone: </b>+64 027 346 5160</p>
                </li>
                <li>
                  <a href='https://www.linkedin.com/in/natali-larroza-125a8b25/' className='text-white' target="_blank">
                    <img  src="img/Iconos/LinkedIn_Logo.svg" className="logoHeader" alt="" style={{ width: "100px" }} />
                  </a>
                  <a href='https://www.youtube.com/@natilarroza149/videos' className='text-white' target="_blank">
                    <img  src="img/Iconos/Logo_Youtube.svg" className="logoHeader" alt="" style={{ width: "80px" }} />
                  </a>
  
                </li>
              </ul>
              </div>
            </MDBCol>
  
            
          </MDBRow>
        </MDBContainer>
  
        <div className='text-center p-3' style={{ backgroundColor: 'rgba(0,0,0,0.7)', color:"white" }}>
          &copy; {new Date().getFullYear()} Copyright:{' '} Natali Larroza
  
        </div>
      </MDBFooter>
  
  
  
  
  
  
  
  
  
  
  
  
  
  
    </div>
    );
  }
  
  export default Footer;









  // <div className="footer" id="contacto">
  
  
  //     <MDBFooter className='text-body text-center text-lg-left'>
  //       <MDBContainer className='p-4'>
  //         <MDBRow>
  //           <MDBCol lg='5' md='12' className='mb-4 mb-md-0 ' id="infoFooter" >
  //             <div className>
  //               <h3 className="tituloFooter"id="infoFooterTitulo">Natali Larroza</h3>
  //             </div>
  //             <div className="infocourseFooter" id="infoFooterTitulo2">
  //               <p>Web Developer<br />
  //                 UX, UI & Multimedia Designer</p>
  //             </div>
  //             <div>
  //             <ul className='myInformation list-unstyled mb-0' id="infoFooterTitulo3">
  //               <li>
  //                 <p><b>Email: </b>natali.larroza@gmail.com </p>
  //                 <p><b>Phone: </b>+64 027 346 5160</p>
  //               </li>
  //               <li>
  //                 <a href='https://www.linkedin.com/in/natali-larroza-125a8b25/' className='text-white' target="_blank">
  //                   <img  src="img/Iconos/LinkedIn_Logo.svg" className="logoHeader" alt="" style={{ width: "100px" }} />
  //                 </a>
  //                 <a href='https://www.youtube.com/@natilarroza149/videos' className='text-white' target="_blank">
  //                   <img  src="img/Iconos/Logo_Youtube.svg" className="logoHeader" alt="" style={{ width: "80px" }} />
  //                 </a>
  
  //               </li>
  //             </ul>
  //             </div>
  //           </MDBCol>
  
  
  
  //           <MDBCol lg='7' md='12' className='mb-4 mb-md-0'>
  //           <div className='registerForm'>
  //             <h2>Get in touch</h2>
  //             <div className='formApply'>
  //               <Form ref={form} noValidate validated={validated} onSubmit={handleSubmit}>
  
  //               <Form.Group className="mb-4" as={Col} controlId="validationCustom01">
  
  //               <Form.Control
  //                 required
  //                 type="text"
  //                 placeholder="Name"
  //                 feedback="Please write your first name."
  //                 defaultValue=""
  //                 name="first_name"
  //                 />
  //                 <Form.Control.Feedback type="invalid">
  //                   Please write your name
  //                 </Form.Control.Feedback>
  //                 <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
  //               </Form.Group>
           
  //           <Form.Group className="mb-4" as={Col} controlId="validationCustomUsername">
            
  //                   <InputGroup hasValidation>
  //                   <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
  //                   <Form.Control
  //                 type="email"
  //                 placeholder="Email"
  //                 aria-describedby="inputGroupPrepend"
  //                 required
  //                 name="email_id"
  //               />
  //               <Form.Control.Feedback type="invalid">
  //                 Please choose an email.
  //               </Form.Control.Feedback>
  //             </InputGroup>
  //           </Form.Group>
  //           <Form.Group className="mb-4" as={Col}  controlId="message">
   
  //             <InputGroup hasValidation>
  //               <Form.Control as="textarea" rows={3}
  //                 type="text"
  //                 placeholder="Message"
  //                 aria-describedby="inputGroupPrepend"
  //                 name="message"
  //                 required
  //               />
  //               <Form.Control.Feedback type="invalid">
  //                 Please write a message.
  //               </Form.Control.Feedback>
  //             </InputGroup>
  //           </Form.Group>
  //         <Form.Group className="mb-3" controlId="checkBox1">
  //           <Form.Check
  //             required
  //             label="I'm not a Robot"
  //             feedback="You must click here."
  //             feedbackType="invalid"
  //           />
  //         </Form.Group>
  //         <div className='btnForm'>
  //         <Button id="btn" type="submit">Send</Button>
  //         </div>
  //       </Form>
  //       </div>
  //       </div>

  //           </MDBCol>
  
            
  //         </MDBRow>
  //       </MDBContainer>
  
  //       <div className='text-center p-3' style={{ backgroundColor: 'rgba(0,0,0,0.7)', color:"white" }}>
  //         &copy; {new Date().getFullYear()} Copyright:{' '} Natali Larroza
  
  //       </div>
  //     </MDBFooter>
  