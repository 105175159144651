import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";



function PortfolioSpruik() {
    return (
<div className="portfolioDentons">
                     <h1 id="dentons">Portfolio</h1>
                     <h2 className="centerh2">Spruik</h2>
                   
                     <h4 id="dentons">Print/Digital Design</h4>

                     <Carousel className="carouselPrintDentons" >
                <div>
                <img
                    src="img/Dentons/GF/gf-10.png"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt="Graphic Design"
                    id="imgCarousel"
                    />
                    <p className="legend">Business Cards</p>
                </div>
                <div>
                <img
                    src="img/Dentons/GF/gf-11.png"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt="Graphic Design"
                    id="imgCarousel"
                    />
                    <p className="legend">Labels</p>
                </div>
                <div>
                <img
                    src="img/Dentons/GF/gf-12.png"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt="Graphic Design"
                    id="imgCarousel"
                    />
                    <p className="legend">Labels</p>
                </div>
                <div>
                <img
                    src="img/Dentons/GF/gf-13.png"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt="Graphic Design"
                    id="imgCarousel"
                    />
                    <p className="legend">Labels</p>
                </div>
                <div>
                <img
                    src="img/Dentons/GF/gf-14.png"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt="Graphic Design"
                    id="imgCarousel"
                    />
                    <p className="legend">Labels</p>
                </div>
                <div>
                <img
                    src="img/Dentons/GF/gf-15.png"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt="Graphic Design"
                    id="imgCarousel"
                    />
                    <p className="legend">Print Sign</p>
                </div>
                <div>
                <img
                    src="img/Dentons/GF/gf-16.png"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt="Graphic Design"
                    id="imgCarousel"
                    />
                    <p className="legend">Menu</p>
                </div>
                <div>
                <img
                    src="img/Dentons/GF/gf-17.png"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt="Graphic Design"
                    id="imgCarousel"
                    />
                    <p className="legend">PVC Banner</p>
                </div>
                <div>
                <img
                    src="img/Dentons/GF/gf-18.png"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt="Graphic Design"
                    id="imgCarousel"
                    />
                    <p className="legend">Banner printing</p>
                </div>

                <div>
                <img
                    src="img/Dentons/GF/gf-20.png"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt="Graphic Design"
                    id="imgCarousel"
                    />
                    <p className="legend">Fridge Magnets</p>
                </div>
     
                <div>
                <img
                    src="img/Dentons/GF/gf-22.png"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt="Graphic Design"
                    id="imgCarousel"
                    />
                    <p className="legend">Print Sign</p>
                </div>
                <div>
                <img
                    src="img/Dentons/GF/gf-25.png"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt="Graphic Design"
                    id="imgCarousel"
                    />
                    <p className="legend">Print Sign</p>
                </div>

                {/* SAO */}

                <div>
                <img
                    src="img/Dentons/Sao/sao_-01.png"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt="Yosemite National Park"
                    id="imgCarousel"
                    />
                    <p className="legend">Business Cards</p>
                </div>
                <div>
                <img
                    src="img/Dentons/Sao/sao_-02.png"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt="Yosemite National Park"
                    id="imgCarousel"
                    />
                    <p className="legend">Menu</p>
                </div>
                <div>
                <img
                    src="img/Dentons/Sao/sao_-03.png"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt="Yosemite National Park"
                    id="imgCarousel"
                    />
                    <p className="legend">Empanada Shape</p>
                </div>
                <div>
                <img
                    src="img/Dentons/Sao/sao_-04.png"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt="Yosemite National Park"
                    id="imgCarousel"
                    />
                    <p className="legend">Stamp</p>
                </div>
                <div>
                <img
                    src="img/Dentons/Sao/sao_-05.png"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt="Yosemite National Park"
                    id="imgCarousel"
                    />
                    <p className="legend">Sign Print Promotion</p>
                </div>
                <div>
                <img
                    src="img/Dentons/Sao/sao_-06.png"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt="Yosemite National Park"
                    id="imgCarousel"
                    />
                    <p className="legend">Sign Print Promotion</p>
                </div>
                <div>
                <img
                    src="img/Dentons/Sao/sao_-07.png"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt="Yosemite National Park"
                    id="imgCarousel"
                    />
                    <p className="legend">Sign Print Promotion</p>
                </div>
                <div>
                <img
                    src="img/Dentons/Sao/sao_-08.png"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt="Yosemite National Park"
                    id="imgCarousel"
                    />
                    <p className="legend">Sign Print Promotion</p>
                </div>
                <div>
                <img
                    src="img/Dentons/Sao/sao_-09.png"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt="Yosemite National Park"
                    id="imgCarousel"
                    />
                    <p className="legend">Event Setup</p>
                </div>

                <div>
                <img
                    src="img/Sophie/mockupSophie-02.png"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt="Yosemite National Park"
                    id="imgCarousel"
                    />
                    <p className="legend">Website</p>
                </div>
                <div>
                <img
                    src="img/Sophie/mockupSophie-01.png"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt="Yosemite National Park"
                    id="imgCarousel"
                    />
                    <p className="legend">Website</p>
                </div>
                <div>
                <img
                    src="img/Sophie/mockupSophie-03.png"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt="Yosemite National Park"
                    id="imgCarousel"
                    />
                    <p className="legend">Website</p>
                </div>



                {/* OSIRIS */}

                <div>
                <img
                    src="img/Dentons/Osiris/osiris-24.png"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt="Graphic Design"
                    id="imgCarousel"
                    />
                    <p className="legend">Business cards & letterhead</p>
                </div>
                <div>
                <img
                    src="img/Dentons/Osiris/osiris-27.png"
                    class="w-100 shadow-1-strong rounded mb-4"
                    alt="Graphic Design"
                    id="imgCarousel"
                    />
                    <p className="legend">Wall sign</p>
                </div>
                
            </Carousel>



            <h4 id="dentons">Videos</h4>

            <div className="topspace">
                <h5 id="center">Reel Natali Larroza - Muledimedia Designer<br /> <br /></h5>
                <h6 id="center">Adobe Illustrator, Adobe After Effects, Adobe Premiere, Adobe Photoshop
                                <br /></h6>
                
                <div className="ratio ratio-16x9" id="carouselPrintDentons">
                    <iframe
                    src="https://www.youtube.com/embed/SWXCtZNbYEY"
                    title="Reel TL9"
                    allowfullscreen
                    >    
                    </iframe>
                </div>
            </div>


            <div className="topspace">
                <h5 id="center">TV News - Intro Animated<br /> <br /></h5>
                <h6 id="center">Adobe Photoshop, Adobe Illustrator, Adobe After Effects, Cinema 4D
                                <br /></h6>
                
                <div className="ratio ratio-16x9" id="carouselPrintDentons">
                    <iframe
                    src="https://www.youtube.com/embed/qtPD5odyD6U"
                    title="Reel TL9"
                    allowfullscreen
                    >    
                    </iframe>
                </div>
            </div>


            <div className="topspace">
                <h5 id="center">Animated Sign  <br /> <br /></h5>
                <h6 id="center">Adobe Photoshop, Adobe Illustrator, Adobe After Effects
                                <br /></h6>
                
                <div className="ratio ratio-16x9" id="carouselPrintDentons">
                    <iframe
                    src="https://www.youtube.com/embed/LZxP-KSVqWI"
                    title="Reel TL9"
                    allowfullscreen
                    >    
                    </iframe>
                </div>
            </div>




        
            <div className="topspace">
                <h5 id="center">Animated Infographic  <br /> <br /></h5>
                <h6 id="center">Adobe Photoshop, Adobe Illustrator, Adobe Premiere
                                <br /></h6>
                
                <div className="ratio ratio-16x9" id="carouselPrintDentons">
                    <iframe
                    src="https://www.youtube.com/embed/ZCBfjAh20Zk"
                    title="Reel TL9"
                    allowfullscreen
                    >    
                    </iframe>
                </div>
            </div>



            <div className="topspace">
                <h5 id="center">Reel - Good Feeling<br /> <br /></h5>
                <h6 id="center">Adobe Photoshop, Adobe Illustrator, Adobe After Effects
                                <br /></h6>
                
                <div className="ratio ratio-16x9" id="carouselPrintDentons">
                    <iframe
                    src="https://www.youtube.com/embed/-2io3X4oHz4"
                    title="Reel TL9"
                    allowfullscreen
                    >    
                    </iframe>
                </div>
            </div>


            <div className="topspace">
                <h5 id="center">Logo animation - Insomniak Records<br /> <br /></h5>
                <h6 id="center">Adobe Illustrator, Adobe After Effects
                                <br /></h6>
                
                <div className="ratio ratio-16x9" id="carouselPrintDentons">
                    <iframe
                    src="https://www.youtube.com/embed/PtPc3jb6YGY"
                    title="Reel TL9"
                    allowfullscreen
                    >    
                    </iframe>
                </div>
            </div>
            

            <div className="topspace">
                <h5 id="center">Promotion animation - South American Oven<br /> <br /></h5>
                <h6 id="center">Adobe Illustrator, Adobe Photoshop, Adobe After Effects
                                <br /></h6>
                
                <div className="ratio ratio-16x9" id="carouselPrintDentons">
                    <iframe
                    src="https://www.youtube.com/embed/l8KWL0gzegs"
                    title="Reel TL9"
                    allowfullscreen
                    >    
                    </iframe>
                </div>
            </div>

            <div className="topspace">
                <h5 id="center">TV News Animation<br /> <br /></h5>
                <h6 id="center">Adobe Illustrator, Adobe Photoshop, Adobe After Effects
                                <br /></h6>
                
                <div className="ratio ratio-16x9" id="carouselPrintDentons">
                    <iframe
                    src="https://www.youtube.com/embed/Hx0O38KNB0g"
                    title="Reel TL9"
                    allowfullscreen
                    >    
                    </iframe>
                </div>
            </div>

            <div className="topspace">
            <h5 id="center">This is a reel where I showed most of the motion graphics made in TV Channel. <br />
                I showed the way that I designed and how is look like on TV <br /> <br />
                One of my main jobs was to design animated templates so that any other operator can change the required text.</h5>
                <br /><br /><h6 id="center">Multimedia design including animation, postproduction and fx <br /> Design of company brand <br /> New opening animation for the television station <br /> Graphic design such as logos and stills
                                <br /><br />
                                Adobe Photoshop, Adobe Illustrator, Adobe Premiere, Adobe After Effects, Cinema 4D</h6>
                
                <div className="ratio ratio-16x9" id="carouselPrintDentons">
                    <iframe
                    src="https://www.youtube.com/embed/bA0vco8_HpE"
                    title="Reel TL9"
                    allowfullscreen
                    >    
                    </iframe>
                </div>
            </div>




           
</div>

    )
}

export default PortfolioSpruik; 