import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';




function Header() {
    return (
        <div className='navBar'>



        <Navbar className='fixed-top-nav' collapseOnSelect expand="lg" variant="dark" >
        <Container>
          <Navbar.Brand id='nav4'   href="/" >Natali Larroza</Navbar.Brand>
          <Navbar.Toggle  aria-controls="basic-navbar-nav" />
          </Container>
          <Container>
          
          
      
          {/* <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll"> */}
       
          
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link className='nav3' id='txtblanco' href="#education">EDUCATION</Nav.Link>
              <Nav.Link className='nav3' id='txtblanco' href="#experience">EXPERIENCE</Nav.Link>
              <Nav.Link className='nav3' id='txtblanco' href="#portfolio">PORTFOLIO</Nav.Link>
              <Nav.Link className='nav3' id='txtblanco' href="#contacto">CONTACT</Nav.Link>
            </Nav>
          </Navbar.Collapse>
          </Container>
    
      </Navbar>
      
      
      
      </div>
    )
};

export default Header;




